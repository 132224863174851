<template>
	<div id="page-login" class="flex w-full layout--middle">
		<div class="sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
			<vx-card class="shadow-none">
				<div class="h-full sm:w-full lg:w-1/2 mx-auto self-center bg-white bg-dark pt-10 pb-10">
					<div class="vx-card__title mb-8">
						<h4 class="mb-4">{{ $t("Connexion") }}</h4>
						<p>{{ $t("Connectez-vous à votre compte") }} {{ name }}</p>
						<vs-alert type="warning" :active="showLoginError" color="danger">
							{{ $t("Les données de connexion ne sont pas correctes.") }}
						</vs-alert>
					</div>
					<!-- email -->
					<div class="center grid">
						<vs-row>
							<vs-col vs-type="flex" w="12" class="p-0">
								<vs-input
									v-model="email"
									v-validate="'required|email'"
									data-vv-validate-on="blur"
									name="email"
									icon="icon icon-mail"
									icon-pack="feather"
									:label-placeholder="$t('Email')"
									class="w-full no-icon-border"
									color="var(--black)"
									@keypress.enter="login"
								/>
							</vs-col>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
								<span v-show="errors.has('email')" class="text-danger text-sm">{{
									errors.first("email")
								}}</span>
							</vs-col>
						</vs-row>
						<!-- mot de passe -->
						<vs-row>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" class="p-0" style="width: 100%">
								<div class="relative w-full">
									<vs-input
										ref="password"
										v-model="password"
										v-validate="'required'"
										icon-pack="feather"
										icon="icon-lock"
										icon-no-border
										:type="password_type"
										data-vv-validate-on="blur"
										name="password"
										:label-placeholder="$t('Mot de passe')"
										class="w-full mt-6 no-icon-border"
										color="var(--black)"
										@keypress.enter="login"
									/>

									<vs-icon
										:icon="password_type == 'password' ? 'icon-eye' : 'icon-eye-off'"
										icon-pack="feather"
										size="1.1rem"
										class="mt-4 icon_eye login_eye_icon cursor-pointer"
										@click="togglePassword"
									/>
								</div>
							</vs-col>
							<vs-col vs-type="flex" vs-justify="start" vs-align="center" style="width: 100%">
								<span v-show="errors.has('password')" class="text-danger text-sm pt-2">{{
									errors.first("password")
								}}</span>
							</vs-col>
						</vs-row>
					</div>
					<!-- mot de passe oublié -->
					<div class="flex flex-wrap justify-between my-5">
						<router-link :to="{ name: 'Sendpassword' }"> {{ $t("Mot de passe oublié ?") }} </router-link>
					</div>

					<!-- soumission du formulaire -->
					<s-button
						ref="submit"
						class="w-full mb-3 vs-con-loading__container"
						:disabled="disabled"
						@click="login"
					>
						{{ $t("Connexion") }}
					</s-button>

					<s-button class="w-full" type="border" @click="createAccount">
						{{ $t("Créer un compte") }}
					</s-button>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import { getDomain, getToken } from "sinao-corejs";
	import { Validator } from "vee-validate";
	import i18n from "../i18n";

	const dict = {
		custom: {
			email: {
				required: i18n.t("Veuillez entrer l'email"),
				email: i18n.t("L'email n'est pas valide")
			},
			password: {
				required: i18n.t("Veuillez entrer un mot de passe")
			}
		}
	};

	Validator.localize("fr", dict);

	export default {
		data() {
			return {
				email: "",
				password: "",
				showLoginError: false,
				disabled: false,
				password_type: "password"
			};
		},
		computed: {
			...mapState(["name"])
		},
		methods: {
			togglePassword() {
				this.password_type = this.password_type === "password" ? "text" : "password";
			},
			createAccount() {
				if (getDomain() === "sinao.app") {
					window.location = "https://www.sinao.fr/lp/essayez-gratuitement-sinao";
				} else {
					this.$router.push({ name: "Register" });
				}
			},
			async login() {
				const { email, password } = this;

				if (email && password) {
					const isFormValid = await this.$validator.validateAll();

					if (isFormValid) {
						this.disabled = true;

						this.$vs.loading({
							type: "default",
							color: "var(--white)",
							background: "var(--primary)",
							container: this.$refs.submit.$el,
							scale: 0.5
						});

						try {
							const login = await this.$store.dispatch("login", {
								email,
								password
							});

							if (login) {
								await this.$store.dispatch("getUser").catch(error => {
									console.log(error);
								});

								this.$router.push({ name: "Apps" });
							} else {
								throw new Error("login failed 1");
							}
						} catch (error) {
							console.log(error);
							this.showLoginError = true;
							this.password = "";
						}

						this.disabled = false;
						this.$vs.loading.close(this.$refs.submit.$el);
					}
				}
			}
		}
	};
</script>

<style lang="scss">
	#page-login {
		.social-login {
			.bg-facebook {
				background-color: #1551b1;
			}
			.bg-twitter {
				background-color: #00aaff;
			}
			.bg-google {
				background-color: #1551b1;
			}
			.bg-github {
				background-color: #333;
			}
		}
	}

	.login_eye_icon {
		margin-top: calc(21px / 2) !important;
	}
</style>
